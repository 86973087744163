<template>
  <div class="ytm-lessons-in-day-wrapper">
    <div style="display: flex; overflow: hidden">
      <div>
        <p class="ytm-default-text ytm-text-violet" style="font-size: 2.75rem">
          {{day}}
        </p>
        <p class="ytm-default-text ytm-text-violet" style="font-size: 1.125rem">
          {{month}}
        </p>
      </div>
      <table class="ytm-table-with-spacing">
        <tr v-for="lesson in lessons" :key="lesson.lessonId" class="ytm-lesson-row">
          <td>
            <p class="ytm-default-text" style="text-align: center; font-size: 1.125rem; line-height: 130%; font-weight: 600">
              {{getHHMM(lesson.dtStart)}}
            </p>
            <p class="ytm-default-text" style="margin-top: 0.2rem; text-align: center; line-height: 130%; font-weight: 400">
              {{getDuration(lesson)}} минут
            </p>
          </td>
          <td>
            <div style="margin-bottom: 0.3rem">
              <span
                  class="ytm-default-text ytm-text-with-overflow"
                  style="font-size: 1.15rem; line-height: 130%; font-weight: 600; cursor: pointer"
                  @click="$router.push('/course/' + lesson.courseLink)"
              >
              {{lesson.courseTitle}}
            </span>
            </div>
            <div style="margin-bottom: 0.2rem">
              <span
                  class="ytm-default-text ytm-text-with-overflow"
                  style="line-height: 130%; font-weight: 600; cursor: pointer"
                  @click="$router.push('/course/' + lesson.courseLink + '/lesson/' + lesson.lessonId)"
              >
                Тема: {{lesson.topic}}
              </span>
            </div>
            <p style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              <svg v-if="lesson.roleId !== 1" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-lesson-people-icon">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M37 0C38.1046 0 39 0.895431 39 2V18C39 19.1046 38.1046 20 37 20H21.4544C19.9478 20 18.9297 18.327 18.9932 16.8217C18.9977 16.715 19 16.6078 19 16.5C19 12.3579 15.6421 9 11.5 9C10.2562 9 9 8.12211 9 6.87834V2C9 0.895432 9.89543 0 11 0H37ZM4.79173 16.9927C4.79173 13.683 7.47474 11 10.7844 11C14.094 11 16.7771 13.683 16.7771 16.9927V17.5919C16.7771 20.9016 14.094 23.5846 10.7844 23.5846C7.47474 23.5846 4.79173 20.9016 4.79173 17.5919V16.9927ZM16.982 33H4.16299C2.30956 33 -0.111048 32.4796 0.00394854 30.6298C0.0465698 29.9441 0.408664 29.1619 1.32316 28.3306C3.2103 26.6151 5.46888 25.591 6.92922 25.1063C7.57616 24.8916 8.25872 24.8286 8.94036 24.8285L12.0834 24.8286C12.8966 24.8286 13.7111 24.9221 14.4647 25.2278C15.758 25.7527 17.6847 26.7471 19.6008 28.3306C20.7583 29.2872 21.1113 30.1788 21.05 30.933C20.9113 32.6418 18.6965 33 16.982 33Z" fill="black"/>
              </svg>
              <svg v-else-if="lesson.isIndividual" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-lesson-people-icon">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7844 0C7.47474 0 4.79173 2.68301 4.79173 5.99266V6.59193C4.79173 9.90159 7.47474 12.5846 10.7844 12.5846C14.094 12.5846 16.7771 9.90159 16.7771 6.59193V5.99266C16.7771 2.68301 14.094 0 10.7844 0ZM4.16299 22H16.982C18.6965 22 20.9113 21.6418 21.05 19.933C21.1113 19.1788 20.7583 18.2872 19.6008 17.3306C17.6847 15.7471 15.758 14.7527 14.4647 14.2278C13.7111 13.9221 12.8966 13.8286 12.0834 13.8286L8.94036 13.8285C8.25872 13.8286 7.57616 13.8916 6.92922 14.1063C5.46888 14.591 3.2103 15.6151 1.32316 17.3306C0.408664 18.1619 0.0465698 18.9441 0.00394854 19.6298C-0.111048 21.4796 2.30956 22 4.16299 22Z" fill="black"/>
              </svg>
              <svg v-else viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-lesson-people-icon">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.79173 5.99266C4.79173 2.68301 7.47474 0 10.7844 0C14.094 0 16.7771 2.68301 16.7771 5.99266V6.59193C16.7771 9.90159 14.094 12.5846 10.7844 12.5846C7.47474 12.5846 4.79173 9.90159 4.79173 6.59193V5.99266ZM16.982 22H4.16299C2.30956 22 -0.111048 21.4796 0.00394854 19.6298C0.0465698 18.9441 0.408664 18.1619 1.32316 17.3306C3.2103 15.6151 5.46888 14.591 6.92922 14.1063C7.57616 13.8916 8.25872 13.8286 8.94036 13.8285L12.0834 13.8286C12.8966 13.8286 13.7111 13.9221 14.4647 14.2278C15.758 14.7527 17.6847 15.7471 19.6008 17.3306C20.7583 18.2872 21.1113 19.1788 21.05 19.933C20.9113 21.6418 18.6965 22 16.982 22ZM22.2643 19.3045C22.2592 19.3666 22.2514 19.427 22.241 19.4856H28.2428C29.6389 19.4856 31.4424 19.1939 31.5554 17.8024C31.6053 17.1883 31.3179 16.4623 30.3753 15.6834C28.815 14.394 27.2462 13.5842 26.193 13.1569C25.5794 12.9079 24.9162 12.8317 24.254 12.8317L21.6947 12.8317C21.1396 12.8317 20.5838 12.8831 20.057 13.0579C19.3106 13.3056 18.3082 13.7264 17.2851 14.3508C18.3528 14.9133 19.5861 15.6866 20.815 16.7022C21.9725 17.6587 22.3255 18.5503 22.2643 19.3045ZM23.1964 1.57145C20.5014 1.57145 18.3166 3.75618 18.3166 6.45119V6.93917C18.3166 9.63417 20.5014 11.8189 23.1964 11.8189C25.8914 11.8189 28.0761 9.63417 28.0761 6.93917V6.45119C28.0761 3.75618 25.8914 1.57145 23.1964 1.57145Z" fill="black"/>
              </svg>
              <span class="ytm-default-text" style="white-space: nowrap">
                {{getName(lesson)}}
              </span>
            </p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {getHHMM} from "@/util/datetime-to-str";

export default {
  name: 'LessonsInDay',
  props: {
    day: {
      type: [String, Number],
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
    lessons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getHHMM(date) { return getHHMM(date); },
    getDuration(lesson) {
      const diff = lesson.dtFinish - lesson.dtStart;
      return Math.round(diff / 1000 / 60);
    },
    getName(lesson) {
      if (lesson.roleId === 1) {
        if (!lesson.isIndividual) {
          return 'Групповой курс';
        } else if (!lesson.student) {
          return 'Ученик не добавлен';
        } else {
          return lesson.student.name;
        }
      } else {
        return lesson.teacher.name;
      }
    },
  },
};
</script>

<style scoped>
.ytm-lessons-in-day-wrapper {
  padding: 1.125rem 1.5rem 1.5rem 1.5rem;
  background: #FFFFFF;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;
  transition: 0.2s ease-in-out;
}

.ytm-lessons-in-day-wrapper:hover {
  transform: scale(1.01);
}

.ytm-table-with-spacing {
  border-spacing: 3.5rem 2rem;
  margin: -2rem 0;
}

@media only screen and (max-width: 800px) {
  .ytm-table-with-spacing {
    border-spacing: 1.5rem 2rem;
  }
}

@media only screen and (max-width: 500px) {
  .ytm-table-with-spacing {
    border-spacing: 8px 2rem;
  }
}

.ytm-text-violet {
  text-align: center;
  font-weight: 600;
  line-height: 130%;
  color: #9829EF;
}

.ytm-lesson-row {
  margin-bottom: 5rem;
}

.ytm-text-with-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ytm-lesson-people-icon {
  position: relative;
  top: 0.1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
</style>