<template>
    <Modal @reset_modal="$emit('reset_modal')">
        <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
            Создать новое занятие
        </div>
        <div v-if="tariffId === -1">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
                Чтобы создать занятие, нужно выбрать тариф
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                        class="ytm-blue-button ytm-default-text"
                        style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                        @click="$router.push('/account?section=tariff')"
                >
                    К выбору тарифа
                </div>
            </div>
        </div>
        <div v-else-if="loading">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
                Загрузка...
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                        class="ytm-blue-button ytm-default-text"
                        style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                        @click="$emit('reset_modal')"
                >
                    Отмена
                </div>
            </div>
        </div>
        <div v-else-if="failed">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem; color: red">
                Упс, произошла ошибка загрузки :(
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                        class="ytm-blue-button ytm-default-text"
                        style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                        @click="$emit('reset_modal')"
                >
                    Отмена
                </div>
            </div>
        </div>
        <div v-else-if="noCoursesAsTeacher">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
                У вас пока нет ни одного курса в роли преподавателя. Исправим?
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                        class="ytm-blue-button ytm-default-text"
                        style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                        @click="$router.push('/courses')"
                >
                    К созданию курса
                </div>
            </div>
        </div>
        <div v-else>
            <DefaultTip style="margin-bottom: 1.25rem">
                <p class="ytm-default-text" style="font-weight: 600; line-height: 130%; color: #FFFFFF; font-size: 0.9rem; margin-bottom: 0.45rem; max-width: 700px">
                    Занятие прикрепляется к&nbspопределённому курсу. После создания занятие появится у&nbspвас и у&nbspвсех участников курса в&nbspрасписании.
                </p>
                <a class="ytm-default-text" style="font-weight: 700; color: #FFFFFF; font-size: 0.9rem" href="/tutorial" target="_blank" rel="noopener noreferer">
                    Узнать больше
                    <svg style="position: relative; top: 0.2rem; width: 0.9rem; height: 1rem" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
                    </svg>
                </a>
            </DefaultTip>
            <SelectGroup class="ytm-modal-input" title="Курс" :options="courses" v-model="course"/>
            <InputGroup class="ytm-modal-input" v-model="formData.topic" title="Тема" placeholder="Тема занятия" :focus="true" @keyup.enter="createLesson"/>
            <DatetimeGroup class="ytm-modal-input" v-model="formData.dtStart" title="Планируемые дата и время начала"/>
            <InputGroup class="ytm-modal-input" v-model="duration" type="number" title="Продолжительность (в минутах)" placeholder="Продолжительность занятия в минутах" @input="onDurationInput" @keyup.enter="createLesson"/>
            <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
                {{errorMsg}}
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                        class="ytm-blue-button ytm-default-text"
                        style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                        @click="createLesson"
                >
                    Создать занятие
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import 'v-calendar/dist/style.css';
import Modal from "@/components/redesign/Modal";
import InputGroup from "@/components/redesign/Form/InputGroup";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import SelectGroup from "@/components/redesign/Form/SelectGroup";
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup";
import DefaultTip from "@/components/redesign/Tips/DefaultTip.vue";

export default {
    name: 'NewLessonModal',
    components: {DefaultTip, SelectGroup, Modal, InputGroup, DatetimeGroup},
    data() {
        return {
            courses: [],
            formData: {
                topic: '',
                dtStart: new Date(),
                dtFinish: null,
            },
            course: null,
            duration: 80,
            is_dd_shown: false,
            creating: false,
            errorMsg: '',
        };
    },
    computed: {
        tariffId() {
            const userInfo = this.$store.getters['user_info/user_info'];
            if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
                return userInfo.tariffState.tariff.id;
            } else {
                return -1;
            }
        },
        loading() {
            return this.$store.getters['courses/loading'];
        },
        failed() {
            return this.$store.getters['courses/failed'];
        },
        noCoursesAsTeacher() {
            return this.$store.getters['courses/noCoursesAsTeacher'];
        },
    },
    methods: {
        onDurationInput() {
            this.duration = Math.max(0, Math.round(this.duration));
            this.duration = Math.min(60 * 12, Math.round(this.duration));
        },
        createLesson() {
            if (this.creating) {
                return;
            }
            this.formData.topic = this.formData.topic.trim();
            if (!this.formData.topic) {
                this.errorMsg = 'Укажите, пожалуйста, тему занятия';
                return;
            }
            if (!this.course) {
                this.errorMsg = 'Не выбран курс';
                return;
            }
            this.errorMsg = '';
            this.creating = true;
            let payload = this.formData;
            payload.dtFinish = new Date(payload.dtStart);
            payload.dtFinish.setTime(payload.dtFinish.getTime() + this.duration * 60 * 1000);
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.post(
                    SERVICE_MAIN_URI + '/createLesson',
                    payload,
                    {
                        headers: authHeader(),
                        params: {shortLink: this.course},
                    },
                ).then(resp => {
                    this.$emit('lesson_created');
                    this.creating = false;
                    this.$emit('reset_modal');
                }).catch(err => {
                    this.creating = false;
                    this.errorMsg = 'Ошибка: не удалось создать занятие';
                });
            });
        },
    },
    mounted() {
        this.$store.dispatch('courses/fetchCourses').then(() => {
            this.courses = [];
            const coursesAsTeacher = this.$store.getters['courses/courses'].asTeacher;
            if (coursesAsTeacher && coursesAsTeacher.length > 0) {
                for (const course of coursesAsTeacher) {
                    let about = (!course.isIndividual ? 'групповой курс' : course.student ? course.student.name : 'ученик не добавлен')
                    this.courses.push({
                        value: course.shortLink,
                        desc: course.title + ' (' + about + ')',
                    });
                }
            } else { // fallback
                this.courses = [{
                    value: '',
                    desc: 'Нет курсов',
                }];
            }
            this.course = this.courses[0].value;
        }).catch(err => {
            console.error(err);
            this.errorMsg = 'Не удалось загрузить курсы :(';
        });
    },
};
</script>

<style scoped>
.ytm-modal-input {
    margin-bottom: 1.25rem;
}
</style>